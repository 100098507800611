<template>
    <div class="h-min-content w-full">
        <ManagementHistory />
    </div>
</template>

<script>
import ManagementHistory from '@/components/Dashboard/Complaints/MonitoringHistoryComplaint/ManagementHistory'
export default {
  components: {
    ManagementHistory
  }
}
</script>
<template>
  <div  class="w-full flex flex-col">
    
    <div v-if="loading" class="flex justify-center items-center">
                 <a-spin>
                     <a-icon slot="indicator" type="loading" style="font-size: 36px" spin />
                </a-spin>
            </div>

<div v-if="loading === false">
<MonitoringHeaderComplaint v-if="sample" :sample="sample" />
    <div v-if="evaluations" class="w-11/12 max-w-screen-xl flex flex-col lg:flex-col lg:items-start">
      <MonitoringCriterionComplaint v-for="option in evaluations" :criterion="option" :key="option.id"/>
    </div>
</div>
      
  </div>
</template>

<script>
import { mapState } from "vuex";
import MonitoringCriterionComplaint from "@/components/Dashboard/Complaints/MonitoringHistoryComplaint/MonitoringCriterionComplaint";
import MonitoringHeaderComplaint from "@/components/Dashboard/Complaints/MonitoringHistoryComplaint/MonitoringHeaderComplaint";

export default {
  components: {
      MonitoringCriterionComplaint,
      MonitoringHeaderComplaint
  },
  async created() {
    const { complaint_code } = this.$route.params;
    this.loading = true;
    let {data, error} = await this.$api.getMonitoring(complaint_code);
    this.loading = false;

    if (error) {
      this.showToast('error', 'Error recuperando la información de seguimiento de queja')
    }

    if (data) {
      const complaint = data;
      this.sample = {
            total: complaint.all_criterion,
            progress: complaint.progress,
            percent:  100 * complaint.progress / complaint.all_criterion
        }        
      this.evaluations = complaint?.evaluations;
    }
  },
  data() {
    return {
      criterion: null,
      sample: null,
      evaluations: null,
      loading: false
    };
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    
      showToast(result = 'info', msg) {
      this.$notification[result]({
                message: 'Resultado',
                description: msg,
                onClick: () => {},
            });
    }
  },
};
</script>
<template>
  <div class="w-11/12 mb-4 bg-white rounded-xl shadow-lg text-gray-600">
      <div v-if="criterion" class="p-3">
         <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseInput type="text" label="Criterio" v-model="criterion.criterion_name" placeholder="00:00:00" :disabled="true"/>
                <BaseRadio label="¿Revisado?" :radioOptions="optionsRevised" v-model="criterion.revised" :disabled="true"/>
            </div>
        </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseRadio label="¿Insumo de supervisión?" :radioOptions="optionsSupplies" v-model="criterion.supplies" :disabled="true"/>
                <BaseInput type="text" label="Tiempo destinado para la gestión" v-model="criterion.evaluation_time" placeholder="00:00:00" :disabled="true"/>
            </div>
        </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseTextarea ref="describe" id="describe" label="Comentarios adicionales" placeholder="Mensaje" v-model="criterion.additional_comments" :disabled="true" maxlength="1000"/>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['criterion'],
  data(){
      return {
        optionsRevised: [
                {id: true, name: 'Sí'},
                {id: false, name: 'No'}
                ],
        optionsSupplies: [
                {id: true, name: 'Sí'},
                {id: false, name: 'No'}
                ]
      }
  },
  methods: {
  }
}
</script>